<template>
    <div class="w-full h-screen flex items-center justify-center">
        <form @submit.prevent="submitResetMail" class="w-1/3">
            <h2 class="text-2xl font-light my-4 text-center">Veuillez svp entré votre mail pour réinitialiser votre mot de passe</h2>
            <input type="email" placeholder="Adresse mail" name="email" v-model="email" class="h-[40px] w-full rounded-md my-5 border outline-none p-3 focus:outline-none">
            <button :disabled="asSend" class="w-full py-4 text-white bg-[#5357ec] rounded-md" type="submit">
                <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                <span v-if="!asSend">Envoyer</span>
            </button>
            <div class="my-6 flex justify-center underline">
                <RouterLink to="/signin">Retour à la page de connexion</RouterLink>
            </div>
        </form>
    </div>
</template>
<script>
import toast from '@/components/ui/toast';
import router from '@/router';
import API from '@/service/API';
import {Loader2} from "lucide-vue-next";
export default {
    name:"ResetPassword",
    components:{
        Loader2
    },
    data(){
        return {
            asSend:false,
            email:""
        }
    },
    methods:{
        submitResetMail(){
            this.asSend = true;
            API.post("/sendPasswordResetEmail",{email:this.email})
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Ouvrir votre boite mail pour réccupérer votre mot de passe",3000);
                    this.email = "";
                    router.replace("/signin");
                }else{
                    toast.error("Oops..",data.data["message"],4000);
                }
            })
            .catch(()=>{toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)})
            .finally(()=>{this.asSend = false})
        }
    }
}
</script>