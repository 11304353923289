import axios from 'axios';
import store from '../store/index.js';
import router from "../router/index.js";

const API = axios.create({
    baseURL: `https://attendance-backend.lce-ci.com/api`,
});

API.interceptors.request.use((config)=>{
    config.headers.Authorization = `Bearer ${store.getters.token}`;
    config.headers.Accept = 'application/json';
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
})

API.interceptors.response.use((response)=>{
    if(response.data["status"]) return response;
    if(!response.data["status"] && (response.status >= 200 && response.status < 300)){
        response.status = 422;
        return response;
    }
},(error)=>{
    console.log("Au tour de l'erreur d'être géré");
    if(error.response){
        console.log("Gestion d'erreur : ", error);
        let _errors = typeof error.response.data.message !== "undefined" ? error.response.data.message : error.response.data;
        if(typeof _errors === "undefined"){
            // if(typeof error.response?.data.message !== 'undefined' ) {
            //     _errors = {"error": [error.response.data.message]};
            // }else{
            //     _errors = {"error": ['Server error']} ;
            // }
        }
        // store.commit("element",errors:_error)
        if(error.response.status === 422){
            console.log("sa passe ici")
            store.dispatch("logout");
            router.replace({ name: "signin" });
        }
    }else{
        alert( error.message + '. Veuillez vérifier votre connexion ou contacter l\'administrateur.' );
    }
    return Promise.reject(error);
})

export default API;