<template>
    <div class="flex items-center justify-between p-2 cursor-pointer" @click="expand">
        <span class="link-title text-2xl">Ressources</span>
        <ChevronDown color="#707071" :size="18"/>
    </div>
    <div class="flex flex-col gap-y-2 px-2" v-if="isExpanded">
        <RouterLink to="/employes" class="link-btn">
            <Users class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Liste</span>
        </RouterLink>

        <RouterLink to="/departements" class="link-btn">
            <Cuboid class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Départements</span>
        </RouterLink>

        <RouterLink to="/documents" class="link-btn">
            <Archive class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Documents</span>
        </RouterLink>

        <RouterLink to="/motifs" class="link-btn">
            <Logs class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Motifs</span>
        </RouterLink>

        <RouterLink to="/shifts" class="link-btn">
            <Clock9 class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Horaires</span>
        </RouterLink>

        <RouterLink to="/joursautorises" class="link-btn">
            <CalendarDays class="icon" :size="18"/>
            <span class="text-2xl text-[#606675]">Jours autorisées</span>
        </RouterLink>
    </div>
</template>
<script>
import { ChevronDown,Users,Cuboid,Clock9,CalendarDays,Logs,Archive } from 'lucide-vue-next';
import { RouterLink } from 'vue-router';
export default {
    name : "sideBarHead4",
    components:{
        ChevronDown,
        Users,
        Cuboid,
        Clock9,
        CalendarDays,
        Logs,Archive,
        RouterLink
    },
    props:[],
    data(){
        return {
            isExpanded : true
        }
    },
    methods:{
        expand(){
            this.isExpanded = !this.isExpanded;
        }
    }
}
</script>