<template>
    <section class="">
        <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Liste des Employés</h2>
            <AddUser />
        </div>
        <div class="">
            <EmployeeTableComponent />

            <!-- <div class="block-header">
                <h2>AJOUTER UNE NOUVELLE PERSONNE</h2>
            </div> -->

            <!-- <NewEmployeeComponent /> -->


            <!-- LOADER -->
            <div class="page-loader-wrapper" v-if="loader">
                <div class="loader">
                    <div class="preloader">
                        <div class="spinner-layer pl-indigo">
                            <div class="circle-clipper left">
                                <div class="circle"></div>
                            </div>
                            <div class="circle-clipper right">
                                <div class="circle"></div>
                            </div>
                        </div>
                    </div>
                    <p>Chargement...</p>
                </div>
            </div>
        </div>

    </section>
</template>

<script>
import EmployeeTableComponent from '@/components/tables/EmployeeTable'
import AddUser from '@/components/create/add-user.vue'

export default {
    name: 'EmployeeView',
    components: {
        EmployeeTableComponent,
        AddUser
    },
    data() {
        return {
            loader: false,
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    mounted() {
        this.loadpage()
    },
    created() {

    },
    methods: {
        loadpage() {
            this.loader = true
            var data = this
            setTimeout(() => {
                data.loader = false
            }, 500);
        }
    }
}

</script>