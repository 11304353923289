<template>
    <div class="bg-white rounded-lg px-4 py-6 mb-3 text-[#2b2b2b]">
        <h3 class="text-2xl underline">Liste des demandés de documents</h3>
        <div v-if="this.listDemande.length == 0" class="alert bg-black mt-3">
                    <center>Pas de données trouvées</center>
        </div>
        <div v-else class="body mt-2">
            <div class="table-responsive" v-if="!isLoading">
                <table class="table table-bordered table-striped table-hover tableEmployee">
                    <thead>
                        <tr>
                            <th>Numéro demande</th>
                            <th>Document demandé</th>
                            <th>Fichier</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <DemandeDocItem v-for="demande in listDemande" :key="demande.id" :demande="demande"/>                            
                    </tbody>
                </table>
            </div>
            <div style="display: flex; justify-content:center" v-else>
                <div class="preloader pl-size-xs">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '../../service/API';
import DemandeDocItem from './demande-doc-item.vue';
export default {
    name:"ListUserDocDemande",
    components:{DemandeDocItem},
    data(){
        return {
            isLoading:true,
            listDemande : []
        }
    },
    mounted(){
        this.getAllUserDocDemande();
    },
    methods:{
        getAllUserDocDemande(){
            this.isLoading = true;
            API.get("/user-docs")
            .then((data)=>{
                this.listDemande = data.data["data"]
            })
            .catch(e=>{console.log(e)})
            .finally(()=>{this.isLoading = false})
        },
    }
}
</script>