<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Gestion des motifs</h2>
    </div>
    <div class="bg-white rounded-lg px-4 py-6 mb-3 text-[#2b2b2b]">
        <h3 class="text-2xl underline">Ajouter un motif</h3>
        <form @submit.prevent="createMotif" class="flex items-end gap-x-3 justify-between mt-3">
            <div class="grow flex flex-col items-start ">
                <label for="Libelle" class="font-light" >Libelle<span class="text-[#f44545]">*</span></label>
                <input type="text" placeholder="Congé maternité" v-model="motifForm.text" name="Libelle" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
            </div>

            <div class="grow flex flex-col items-start ">
                <label for="nbjour" class="font-light" >Nombre de jour<span class="text-[#f44545]">*</span></label>
                <input type="number" min="0" placeholder="0" v-model="motifForm.days" name="nbjour" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
            </div>

            <div class="grow flex flex-col items-start ">
                <label for="is_payed" class="font-light" >Motif payé<span class="text-[#f44545]">*</span></label>
                <select v-model="motifForm.is_payed" name="is_payed" id="is_payed" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                    <option value="">Le motif est il payé</option>
                    <option value="1">Payé</option>
                    <option value="0">Pas payé</option>
                </select>
            </div>

            <div class="grow flex flex-col items-start ">
                <label for="is_leave" class="font-light" >Type de motif<span class="text-[#f44545]">*</span></label>
                <select v-model="motifForm.is_leave" name="is_leave" id="is_leave" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                    <option value="">Le motif est il un congé</option>
                    <option value="1">Congé</option>
                    <option value="0">Pas congé</option>
                </select>
            </div>

            <div class="grow flex flex-col items-start ">
                <label for="is_out_of_office" class="font-light" >Hors-bureau<span class="text-[#f44545]">*</span></label>
                <select v-model="motifForm.is_out_of_office" name="is_out_of_office" id="is_out_of_office" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                    <option value="">Le motif est il hors-bureau</option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </select>
            </div>

            <div class="grow flex flex-col items-start ">
                <label for="is_overtime" class="font-light" >Heure sup<span class="text-[#f44545]">*</span></label>
                <select v-model="motifForm.is_overtime" name="is_overtime" id="is_overtime" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
                    <option value="">Heure sup</option>
                    <option value="1">Oui</option>
                    <option value="0">Non</option>
                </select>
            </div>

            <button type="submit" class="py-4 rounded-md grow text-white bg-[#5357ec]">
                <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                <span v-if="!asSend">Enregistrer</span>
            </button>
        </form>
    </div>

    <div class="bg-white rounded-lg px-4 py-6 mb-3 text-[#2b2b2b]">
        <h3 class="text-2xl underline">Liste des motifs</h3>
        <div v-if="this.motifs.length == 0" class="alert bg-black mt-3">
                    <center>Pas de données trouvées</center>
        </div>
        <div v-else class="body">
            <div class="table-responsive" v-if="!isLoading">
                <table class="table table-bordered table-striped table-hover tableEmployee">
                    <thead>
                        <tr>
                            <th>Libelle</th>
                            <th>Nombre de jour</th>
                            <th>Motif payé</th>
                            <th>Type de motif</th>
                            <th>Hors-bureau</th>
                            <th>Heure sup</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="motif in motifs" :key="motif.id">
                            <td>
                                <div class="form-line">
                                    <input v-model="motif.text" type="text" id="libelle" class="form-control" placeholder="Entrer le libelle">
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <input v-model="motif.days" type="number" id="days" class="form-control" placeholder="Entrer le nombre jour">
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <select v-model="motif.is_payed" class="form-control" style="width: 100px;">
                                        <option value="1">Payé</option>
                                        <option value="0">Pas payé</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <select v-model="motif.is_leave" class="form-control" style="width: 100px;">
                                        <option value="1">Congé</option>
                                        <option value="0">Pas congé</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <select v-model="motif.is_out_of_office" class="form-control" style="width: 100px;">
                                        <option value="1">Oui</option>
                                        <option value="0">Non</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <select v-model="motif.is_overtime" class="form-control" style="width: 100px;">
                                        <option value="1">Oui</option>
                                        <option value="0">Non</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <button
                                    v-on:click="updateMotif(motif.id,motif.text,motif.days,motif.is_payed,motif.is_leave,motif.is_out_of_office,motif.is_overtime)"
                                    type="button" class="btn bg-blue btn-circle waves-effect waves-circle waves-float">
                                        <i class="material-icons">send</i>
                                </button>
                                        -
                                <button v-on:click="removeMotif(motif.id)" type="button"
                                    class="btn bg-red btn-circle waves-effect waves-circle waves-float">
                                        <i class="material-icons">delete</i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display: flex; justify-content:center" v-else>
                <div class="preloader pl-size-xs">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import toast from '@/components/ui/toast';
import API from '@/service/API';
import {
    Loader2
} from "lucide-vue-next";
 export default {
    name: "MotifView",
    components:{
        Loader2
    },
    data(){
        return {
            asSend:false,
            motifForm:{
                text:"",
                days:0,
                is_payed:0,
                is_leave:0,
                is_out_of_office:0,
                is_overtime:0
            },
            motifs:[],
            isLoading:true
        }
    },
    mounted(){
        this.getAllMmotif()
    },
    methods:{
        clearForm(){
            this.motifForm = {
                text:"",
                days:0,
                is_payed:0,
                is_leave:0,
                is_out_of_office:0,
                is_overtime:0
            }
        },
        getAllMmotif(){
            API.get("/getTypesAndMotifs")
            .then((data)=>{
                this.motifs =  data.data["motifs"];
            })
            .catch(e=>{console.log(e)})
            .finally(()=>{
                this.isLoading = false;
            })
        },
        createMotif(){
            this.asSend = true;
            API.post("/addMotif",this.motifForm)
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Motif ajouté",3000);
                    this.getAllMmotif();
                    this.clearForm();
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
            .finally(()=>{this.asSend = false})
        },
        updateMotif(id,text,days,is_payed,is_leave,is_out_of_office,is_overtime){
            API.post("/updateMotif/"+id,{
                text:text,
                days:days,
                is_payed:is_payed,
                is_leave:is_leave,
                is_out_of_office:is_out_of_office,
                is_overtime:is_overtime
            })
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Motif Modifi",3000);
                    this.getAllMmotif();
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
        removeMotif(id){
            API.post("/removeMotif/"+id)
            .then(data=>{
                if(data.data["status"]){
                    toast.success("Succès","Motif ajouté",3000);
                    this.getAllMmotif()
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
    }
 }
</script>