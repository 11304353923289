<template>
    <div class="w-full h-full text-black">
        <h2 class="text-[20px] font-normal">Mes documents</h2>
        <p class="font-extralight my-4">Visualiser la liste des documents demandés</p>
        <div v-if="docs.length == 0" class="w-full mt-3 h-[45vh] flex items-center justify-center">
            <div class="flex flex-col items-center gap-y-3">
                <CircleOff :size="46" strokeWidth="1"/>
                <p class="font-extralight">Aucun document trouvé</p>
            </div>
        </div>
        <div v-else class="w-full mt-3 min-h-[45vh] flex flex-col items-start gap-y-2">
            <div v-for="doc in docs" :key="doc.id" class="w-full p-3 rounded-md bg-gray-100 flex items-center gap-x-5 justify-between">
                <p>{{ doc.document.title }}</p>
                <button v-on:click="downloadFile(doc.file_path)" class="underline">Télécharger</button>
            </div>
        </div>
    </div>
</template>
<script>
import {CircleOff} from "lucide-vue-next"
import API from "../../../service/API";
export default {
    name:"ProfilDoc",
    components:{CircleOff},
    mounted(){
        this.getMyDocs(localStorage.getItem("userId") ?? this.$store.getters.userId);
    },
    data(){
        return {
            loading:true,
            docs:[],
        }
    },
    methods:{
        getMyDocs(id){
            API.get("/userdocs/"+id)
            .then((data)=>{
                this.docs = data.data["data"];
            })
            .catch()
            .finally(()=>{
                this.loading = false;
            })
        },
        getFileName(data){
            return data.split("documents/")[1]
        },
        downloadFile(link){
            fetch(link).then(response => response.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = this.getFileName(link);
                a.click();
                URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('File download failed:', error);
            });
        }
    }
}
</script>