<template>
    <div className=" w-[60%] py-[15px] mx-auto">
        <div class=" text-[20px] font-normal px-[25px] text-black text-center">
            Document
        </div>
        <div class="text-black px-[25px] mt-4 text-[14px] font-extralight text-center">
            Les champs du formulaire suivis d'un <span class="text-[#f44545]">*</span> sont obligatoire. veuillez lire le formulaire
            avant soummition
        </div>
        <form @submit.prevent="submitForm" class="px-[25px] text-black mt-4">
            <div class="w-full flex flex-col items-start mt-2">
                <label for="documentType" class="font-extralight" >Sélectionner le type de document souhaité<span class="text-[#f44545]">*</span></label>
                    <select v-model="documentType" name="documentType" id="documentType" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none bg-[#f9fafb] cursor-pointer">
                        <option value="">Choisissez le type</option>
                        <option v-on:onclick="selectDoc(doc.description)" v-for="doc in typeDoc" :key="doc.id" :value="doc.id"> 
                            {{doc.title }} 
                        </option>
                    </select>
            </div>
            <div v-if="documentType" class="w-full my-2 p-3 flex rounded-lg items-start gap-x-4 bg-[#eeeefe] text-[#332e74]">
                <Info :size="18"/>
                <p class="grow font-light text-lg">{{ typeDoc.filter((doc)=>(doc.id == documentType))[0].description }}</p>
            </div>
            <div className="w-full mt-4 gap-x-3 flex items-center justify-end">
                <button type="submit" :disabled="asSend" class="w-full py-3 bg-[#5357ec] text-white rounded-md">
                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                    <span v-if="!asSend">Enregistrer</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import toast from "../ui/toast";
import API from "@/service/API.js";
import {
    Loader2,Info
} from "lucide-vue-next";
export default {
    name:"NewDoc",
    components:{
        Loader2,Info
    },
    data(){
        return {
            asSend:false,
            documentType:null,
            description:"",
            typeDoc:[]
        }
    },
    mounted(){
        this.getAllDocumentType()
    },
    methods:{
        selectDoc(desc){
            this.desciption = desc;
        },
        getAllDocumentType(){
            API.get("/documents")
            .then((data)=>{
                this.typeDoc = data.data["data"];
            })
            .catch(e=>{console.log(e)})
        },
        submitForm(){
            this.asSend = true;
            const formData = new FormData();
            formData.append("user_id",localStorage.getItem("userId") ?? this.$store.getters.userId);
            formData.append("doc_id",this.documentType);
            API.post("user-docs",formData)
            .then((response)=>{
                if(response.data["status"]){
                    toast.success("Succes","Votre demande à été pris en compte et est en cours de traitement",3000)
                }else{
                    toast.error("Echèc",response.data["message"],5000)
                }
            })
            .catch(()=>{
                console.log("erreur sur serveur!!!!!")
            })
            .finally(()=>{
                this.asSend = false;
            })
        }
    }
}
</script>