<template>
            <div className=" w-[45%] py-[15px] mx-auto">
                <div class=" text-[20px] font-normal px-[25px] text-black text-center">
                    Demander {{ title }}
                </div>
                <div class="text-black px-[25px] mt-4 text-[14px] font-extralight text-center">
                    Les champs du formulaire suivis d'un <span class="text-[#f44545]">*</span> sont obligatoire. veuillez lire le formulaire
                    avant soummition
                </div>
                <form @submit.prevent="submitForm" class="px-[25px] text-black mt-4">
                    <div v-if="permType != 'conge'" class="w-full flex flex-col items-start ">
                        <label for="type" class="font-extralight" >Type de l'exception<span class="text-[#f44545]">*</span></label>
                        <select v-model="formdata.types" name="type" id="type" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none bg-[#f9fafb] cursor-pointer">
                            <option value="">Choisissez le type</option>
                            <option v-for="tp in types" :key="tp.id" :value="tp.id"> 
                                {{tp.label }}
                            </option>
                        </select>
                    </div>
                    <div class="w-full flex flex-col items-start mt-2">
                        <label for="motif" class="font-extralight" >Motif de l'exception<span class="text-[#f44545]">*</span></label>
                        <select v-model="formdata.motif" name="motif" id="motif" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none bg-[#f9fafb] cursor-pointer">
                            <option value="">Choisissez le motif</option>
                            <option v-for="mtf in motifs" :key="mtf.id" :value="mtf.id"> 
                                {{mtf.text }} 
                            </option>
                        </select>
                    </div>
                    <div class="w-full flex items-center justify-between pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="debut" class="font-light" >Début<span class="text-[#f44545]">*</span></label>
                            <input v-model="formdata.debut" type="date" name="debut" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="fin" class="font-light" >Fin<span class="text-[#f44545]">*</span></label>
                            <input v-model="formdata.fin" type="date" name="fin" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                        </div>
                    </div>
                    <div class="w-full flex flex-col items-start mt-2">
                        <label for="detail" class="font-extralight">Détails<span class="text-[#f44545]">*</span></label>
                        <textarea v-model="formdata.details" name="detail" id="" class="rounded-md bg-[#f9fafb] border p-5 h-[25vh] w-full"></textarea>
                    </div>
                    <div class="w-full flex justify-between mt-4 text-black">
                        <label for="pience" class="font-extralight">Pièce jointe</label>
                        <button v-on:click="clickFile" class="text-[#5357eb] flex items-center gap-x-3">
                            <Plus :size="20" stroke-width="1.2"/>
                            Choisir un fichier
                        </button>
                    </div>
                    <div class="w-full p-3 flex flex-wrap gap-3 items-start">
                        <div v-for="(file,idx) in files" :key="file.lastModifiedDate" class="h-20 w-1/5 border rounded p-2 flex items-start justify-between relative">
                            <div :class="getBgColor(file.type)" class="w-[25%] h-full flex items-center justify-center text-[12px] text-white rounded">
                                {{ getType(file.type) }}
                            </div>
                            <p class="text-[12px] w-[73%]">{{ formatText(file.name) }}</p>
                            <span v-on:click="removeFile(idx)" class="h-6 w-6 top-0 right-0 translate-x-[50%] translate-y-[-50%] cursor-pointer rounded-full absolute bg-red-500 flex items-center justify-center">
                                <X color="#fff" :size="12" />
                            </span>
                        </div>
                        <input type="file" v-on:change="selectFiles" name="filefield" multiple accept="image/*,.pdf" ref="file_input_new" class="hidden">
                    </div>
                    <div className="w-full mt-4 gap-x-3 flex items-center justify-end">
                                <button type="submit" :disabled="asSend" class="w-full py-3 bg-[#5357ec] text-white rounded-md">
                                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                                    <span v-if="!asSend">Enregistrer</span>
                                </button>
                    </div>
                </form>
            </div>
</template>
<script>
import {
    X,
    Loader2
} from "lucide-vue-next";
import toast from "../ui/toast";
import API from "@/service/API.js";
export default {
    name:"NewException",
    components:{
        X,
        Loader2,
    },
    props:["permType","title"],
    data(){
        return {
            asSend:false,
            types:[],
            motifs:[],
            files:[],
            formdata:{
                types:"",
                motif:"",
                details:"",
                debut:null,
                fin:null
            }
        }
    },
    mounted(){
        this.getMotif();
    },
    methods:{
        resetForm(){
            this.formdata = {
                types:"",
                motif:"",
                details:"",
                debut:null,
                fin:null
            }
        },
        getMotif(){
            API.get("/getTypesAndMotifs")
            .then((data)=>{
                this.motifs = this.permType == "conge" ? data.data["motifs"].filter((el)=> el["is_leave"] == 1) : data.data["motifs"].filter((el)=> el["is_leave"] != 1);
                this.types = this.permType == "conge" ? data.data["types"].filter((el)=> el["symbol"] == "a") : data.data["types"];
                this.formdata.types = this.permType == "conge" ? data.data["motifs"].filter((el)=> el["is_leave"] == 1)[0]["id"] : ""
            })
            .catch(e=>{console.log(e)})
        },
        clickFile(){
            this.$refs.file_input_new.click();
        },
        selectFiles(e){
            if (!e.target.files?.[0]) return;
            this.files = [...e.target.files]
            console.log(e.target.files);
        },
        getType(type){
            const libelle = type.split("/")[0];
            console.log(libelle)
            return libelle == "image" ? "IMG" : "DOC"
        },
        getBgColor(type){
            return this.getType(type) == "IMG" ? "bg-[#5357eb]": "bg-red-500"
        },
        formatText(libelle){
            return libelle.length >= 21 ? libelle.substring(0,20) + "..." : libelle;
        },
        removeFile(index){
            const tab = [...this.files];
            tab.splice(index,1);
            this.files = [...tab];
        },
        submitForm(){
            this.asSend = true;
            const formData = new FormData();
            formData.append("type_id",this.formdata.types);
            formData.append("motif_id",this.formdata.motif);
            formData.append("date_start", this.formdata.debut);
            formData.append("date_end", this.formdata.fin);
            formData.append("hour",null);
            formData.append("other_motif",this.formdata.details);
            if(this.files.length != 0){
                formData.append("file",this.files[0]);
            }
            API.post("askForPermission",formData)
            .then((response)=>{
                if(response.data["status"]){
                    toast.success("Succes","Exception Enregistré, vous recevrez la réponse sur mon mail",3000)
                }else{
                    toast.error("Echèc",response.data["message"],5000)
                    this.resetForm();
                }
            })
            .catch()
            .finally(()=>{
                this.asSend = false;
            })
        }
    }
}
</script>