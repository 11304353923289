<template>
    <div className=" w-[45%] py-[15px] mx-auto">
        <div class=" text-[20px] font-normal px-[25px] text-black text-center">
            Hors bureau
        </div>
        <div class="text-black px-[25px] mt-4 text-[14px] font-extralight text-center">
            Les champs du formulaire suivis d'un <span class="text-[#f44545]">*</span> sont obligatoire. veuillez lire le formulaire
            avant soummition
        </div>
        <form @submit.prevent="submitForm" class="px-[25px] text-black mt-4">
            <div class="w-full flex items-center justify-between pt-4 text-black">
                <div class="w-[49%] flex flex-col items-start ">
                    <label for="debut" class="font-light" >Début<span class="text-[#f44545]">*</span></label>
                    <input v-model="formdata.debut" type="date" name="debut" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                </div>

                <div class="w-[49%] flex flex-col items-start ">
                    <label for="fin" class="font-light" >Fin<span class="text-[#f44545]">*</span></label>
                    <input v-model="formdata.fin" type="date" name="fin" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                </div>
            </div>
            <div v-if="isInMultipleDay" class="w-full flex items-center justify-between pt-4 text-black">
                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="hour_start" class="font-light" >Heure début<span class="text-[#f44545]">*</span></label>
                            <input v-model="formdata.hour_start" type="time" name="hour_start" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                        </div>

                        <div class="w-[49%] flex flex-col items-start ">
                            <label for="hour_end" class="font-light" >Heure fin<span class="text-[#f44545]">*</span></label>
                            <input v-model="formdata.hour_end" type="time" name="hour_end" class="h-[40px] w-full rounded-md border outline-none bg-[#f9fafb] p-3 focus:outline-none">
                        </div>
            </div>
            <div className="w-full mt-4 gap-x-3 flex items-center justify-end">
                <button type="submit" :disabled="asSend" class="w-full py-3 bg-[#5357ec] text-white rounded-md">
                    <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                    <span v-if="!asSend">Enregistrer</span>
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import toast from "../ui/toast";
import API from "@/service/API.js";
import {
    Loader2
} from "lucide-vue-next";
export default {
    name : "AddHorsService",
    components:{
        Loader2
    },
    data(){
        return {
            asSend:false,
            type:"",
            motif:"",
            formdata:{
                jour:null,
                fin:null,
                hour_start:null,
                hour_end:null
            }
        }
    },
    mounted(){
        this.getMotif();
    },
    computed:{
        isInMultipleDay(){
            return (this.formdata.debut != null && this.formdata.fin != null && this.formdata.debut === this.formdata.fin);
        }
    },
    methods:{
        resetForm(){
            this.formdata = {
                jour:null,
                fin:null,
                hour_start:null,
                hour_end:null
            }},
            getMotif(){
            API.get("/getTypesAndMotifs")
            .then((data)=>{
                this.type =  data.data["types"].filter((el)=> el["symbol"] == "s")[0]["id"]
                this.motif = data.data["motifs"].filter((el) => el.is_out_of_office == 1)[0]["id"]
            })
            .catch(e=>{console.log(e)})
        },
        submitForm(){
            this.asSend = true;
            const {debut,fin,hour_start,hour_end} = this.formdata;
            const formData = new FormData();
            formData.append("type_id",this.type);
            formData.append("motif_id",this.motif);
            formData.append("date_start", debut);
            formData.append("date_end", fin);
            if(debut === fin){
                formData.append("hour_start", hour_start);
                formData.append("hour_end", hour_end);
            }
            API.post("askForPermission",formData)
            .then((response)=>{
                console.log("Dans overtime : ",response);
                if(response.data["status"]){
                    toast.success("Succes","Requête enregistré, à bientôt",3000)
                }else{
                    toast.error("Echèc",response.data["message"],5000)
                }
                this.resetForm();
            })
            .catch(()=>{
                console.log("erreur sur serveur!!!!!")
            })
            .finally(()=>{
                this.asSend = false;
            })
        }
    }
}
</script>