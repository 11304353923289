<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Gestion des documents</h2>
    </div>
    <div class="bg-white rounded-lg px-4 py-6 mb-3 text-[#2b2b2b]">
        <h3 class="text-2xl underline">Ajouter un type de document</h3>
        <form @submit.prevent="createDocument" class="flex items-end gap-x-3 justify-between mt-3">
            <div class="grow flex flex-col items-start ">
                <label for="Libelle" class="font-light" >Libelle<span class="text-[#f44545]">*</span></label>
                <input type="text" placeholder="Libelle doc" v-model="motifForm.title" name="Libelle" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
            </div>
            <div class="grow flex flex-col items-start ">
                <label for="description" class="font-light" >Description<span class="text-[#f44545]">*</span></label>
                <input type="text" placeholder="Description doc" v-model="motifForm.description" name="description" class="h-[40px] w-full rounded-md border outline-none p-3 focus:outline-none">
            </div>
            <button type="submit" class="py-4 rounded-md grow text-white bg-[#5357ec]">
                <Loader2 v-if="asSend" class="animate-spin mx-auto"/>
                <span v-if="!asSend">Enregistrer</span>
            </button>
        </form>
    </div>

    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-2 text-black font-light rounded-md">
                <span v-bind:class="getActiveClass('Type de document')" v-on:click="selectBar('Type de document')">Type de document</span>
                <span v-bind:class="getActiveClass('Demande de document en cours')" v-on:click="selectBar('Demande de document en cours')">Demande de document en cours</span>
            </div>
        </div>
    </div>

    <ListeTypeDocTab v-if="getActiveTab('Type de document')"/>
    <ListUserDocDemande v-if="getActiveTab('Demande de document en cours')"/>
</template>
<script>
import toast from '@/components/ui/toast';
import API from '@/service/API';
import ListeTypeDocTab from "@/components/tables/liste-type-doc-tab.vue";
import ListUserDocDemande from "@/components/tables/list-user-doc-demande.vue";
import {
    Loader2
} from "lucide-vue-next";
export default {
    name:"DocumentUi",
    components:{Loader2,ListeTypeDocTab,ListUserDocDemande},
    data(){
        return {
            active:"Type de document",
            asSend:false,
            motifForm:{
                title:"",
                description:"",
            },
        }
    },
    methods:{
        clearForm(){
            this.motifForm = {
                title:"",
                description:"",
            }
        },
        createDocument(){
            this.asSend = true;
            API.post("/documents",this.motifForm)
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Type de document ajouté",3000);
                    this.getAllDocType();
                    this.clearForm();
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
            .finally(()=>{this.asSend = false})
        },
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        selectBar(libelle){
            this.active = libelle;
        },
        getActiveTab(libelle){
            return this.active == libelle
        },
    }
}
</script>