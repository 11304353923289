<template>
    <nav class="bg-white p-6 h-screen border-[#dbdee2] border-r min-w-[20%] overflow-y-scroll">
        <div class="mb-6 pb-3 border-b border-[#dbdee2] flex items-center justify-between">
            <div class="flex items-center gap-4">
                <div class="h-[42px] w-[42px] rounded-md bg-[#3c41e9]"></div>
                <span class="text-3xl text-black font-semibold">Attendance RH</span>
            </div>
        </div>
        <div class="flex flex-col gap-y-2">
            <sideBarHaeder1 v-if="isRhMember"/>
            <sideBarHead2 />
            <sideBarHead3 v-if="isRhMember"/>
            <sideBarHead4/>
            <sideBarHead5 v-if="isSecurite"/>
        </div>
    </nav>
</template>
<script>
import sideBarHaeder1 from './side-bar-haeder.vue';
import sideBarHead2 from './side-bar-head2.vue';
import sideBarHead3 from './side-bar-head3.vue';
import sideBarHead4 from './side-bar-head4.vue';
import sideBarHead5 from './side-bar-head5.vue';
export default{
    name : "SideBar",
    components:{
        sideBarHaeder1,
        sideBarHead2,
        sideBarHead3,
        sideBarHead4,
        sideBarHead5
    },
    data(){
        return {}
    },
    props:{},
    computed:{
        isRhMember(){
            return (this.$store.getters.profil ?? "").toLowerCase().includes("ressource");
        },
        isSecurite(){
            return (this.$store.getters.profil ?? "") === "securite";
        }
    },
    created(){},
    methods:{
        navigateTo(){
            console.log();
        }
    }
}
</script>