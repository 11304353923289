<template>
    <div class="bg-white rounded-lg px-4 py-6 mb-3 text-[#2b2b2b]">
        <h3 class="text-2xl underline">Liste des types de documents</h3>
        <div v-if="this.typeDoc.length == 0" class="alert bg-black mt-3">
                    <center>Pas de données trouvées</center>
        </div>
        <div v-else class="body mt-2">
            <div class="table-responsive" v-if="!isLoading">
                <table class="table table-bordered table-striped table-hover tableEmployee">
                    <thead>
                        <tr>
                            <th>Libelle</th>
                            <th>Description</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="doc in typeDoc" :key="doc.id">
                            <td>
                                <div class="form-line">
                                    <input v-model="doc.title" type="text" id="title" class="form-control" placeholder="Entrer le libelle">
                                </div>
                            </td>
                            <td>
                                <div class="form-line">
                                    <input v-model="doc.description" type="text" id="description" class="form-control" placeholder="Entrer le nombre jour">
                                </div>
                            </td>
                            <td>
                                <button
                                    v-on:click="updateDocument(doc.id,doc.title,doc.description)"
                                    type="button" class="btn bg-blue btn-circle waves-effect waves-circle waves-float">
                                        <i class="material-icons">send</i>
                                </button>
                                        -
                                <button v-on:click="removeDocument(doc.id)" type="button"
                                    class="btn bg-red btn-circle waves-effect waves-circle waves-float">
                                        <i class="material-icons">delete</i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display: flex; justify-content:center" v-else>
                <div class="preloader pl-size-xs">
                    <div class="spinner-layer pl-indigo">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "../../service/API";
import toast from '@/components/ui/toast'
export default {
    name:"ListeTypeDocTab",
    components:{},
    data(){
        return {
            isLoading:true,
            typeDoc:[]
        }
    },
    mounted(){
        this.getAllDocType();
    },
    methods:{
        getAllDocType(){
            this.isLoading = true;
            API.get("/documents")
            .then((data)=>{
                this.typeDoc = data.data["data"];
            })
            .catch(e=>{console.log(e)})
            .finally(()=>{this.isLoading = false})
        },
        updateDocument(id,title,description){
            API.put("/documents/"+id,{
                title:title,
                description:description,
            })
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Type document Modifié",3000);
                    this.getAllDocType();
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
        removeDocument(id){
            API.delete("/documents/"+id)
            .then(data=>{
                if(data.data["status"]){
                    toast.success("Succès","Type document supprimé",3000);
                    this.getAllDocType()
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
    }
}
</script>