<template>
    <div class="mb-4 flex items-center justify-between">
            <h2 class="text-4xl text-[#2b2b2b]">Mes Demandes</h2>
    </div>
    <div class="bg-white rounded-lg p-4 mb-3">
        <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-2 text-black font-light rounded-md">
                <span v-bind:class="getActiveClass('Congé')" v-on:click="selectBar('Congé')">Congé</span>
                <span v-bind:class="getActiveClass('Permission')" v-on:click="selectBar('Permission')">Permission</span>
                <span v-bind:class="getActiveClass('Heure supplémentaire')" v-on:click="selectBar('Heure supplémentaire')">Heure supplémentaire</span>
                <span v-bind:class="getActiveClass('Document')" v-on:click="selectBar('Document')">Document</span>
                <span v-bind:class="getActiveClass('Hors bureau')" v-on:click="selectBar('Hors bureau')">Hors bureau</span>
            </div>
        </div>
    </div>
    <div class="bg-white rounded-lg p-4">
        <div v-if="getActiveTab('Congé')" class="w-full">
            <NewException permType="conge" title="Demande congé"/>
        </div>
        <div v-if="getActiveTab('Permission')" class="w-full">
            <NewException permType="permission" title="Demande de permission"/>
        </div>
        <div v-if="getActiveTab('Heure supplémentaire')" class="w-full">
            <NewOvertime />
        </div>
        <div v-if="getActiveTab('Document')" class="w-full">
            <NewDoc />
        </div>
        <div v-if="getActiveTab('Hors bureau')" class="w-full">
            <AddHorsService />
        </div>
    </div>
</template>
<script>
import NewDoc from "@/components/create/new-doc.vue";
import NewException from "@/components/create/new-exception.vue";
import NewOvertime from "@/components/create/new-overtime.vue";
import AddHorsService from "@/components/create/add-hors-service.vue"
export default{
    name:"MyDemande",
    components:{
        NewException,
        NewOvertime,
        NewDoc,
        AddHorsService
    },
    data(){return{
        active:"Congé",
    }},
    methods:{
        getActiveClass(libelle){
            return `px-4 py-3 rounded-md cursor-pointer ${libelle == this.active ? "bg-[#5357eb] text-white" : "bg-[#f9fafb] text-black"}`;
        },
        selectBar(libelle){
            this.active = libelle;
        },
        getActiveTab(libelle){
            return this.active == libelle
        },
    }
}
</script>