<template>
    <div class="row clearfix">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card">

                <!-- HEADER -->
                <div class="header">
                    <div class="btn-group" role="group">
                        <button v-on:click="getrapportglobal()" type="button" class="btn waves-effect waves-light"
                            :class="{ 'bg-blue-grey': rapportfilter == 'global' }" data-toggle="modal"
                            data-target="#globalrapport">RAPPORT GLOBAL</button>
                        <button v-on:click="rapportfilter = 'indiv'" type="button"
                            :class="{ 'bg-blue-grey': rapportfilter == 'indiv' }"
                            class="btn waves-effect waves-light">RAPPORT INDIVIDUEL</button>
                    </div>
                </div>

                <!-- TABLE -->
                <div v-if="this.employees.length == 0" class="alert bg-black">
                    <center>Pas de données trouvées</center>
                </div>
                <div v-else class="body">
                    <div class="table-responsive" v-if="!table_load">
                        <table class="table table-bordered table-striped table-hover tableEmployee">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Matricule</th>
                                    <th>Nom</th>
                                    <th>Prénoms</th>
                                    <th>Email</th>
                                    <th>Contact</th>
                                    <th>Département</th>
                                    <th>Service de :</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="employee in employees" :key="employee.id"
                                    v-on:click="getrapportindiv(employee.id)" data-toggle="modal"
                                    data-target="#individualrapport">
                                    <td> {{ employee.id }} </td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="employee.matricule" type="text" id="matricule"
                                                class="form-control" placeholder="Entrer le matricule">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="employee.first_name" type="text" id="lastname"
                                                class="form-control" placeholder="Entrer le libéllé du département">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="employee.last_name" type="text" id="lastname"
                                                class="form-control" placeholder="Entrer le libéllé du département">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="employee.email" type="mail" id="lastname" class="form-control"
                                                placeholder="Entrer le libéllé du département">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <input v-model="employee.phone" type="phone" id="lastname" class="form-control"
                                                placeholder="Entrer le libéllé du département">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <select v-model="employee.profile_id" class="form-control"
                                                style="width: 100px;">
                                                <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
                                                    {{ profile.label }}</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <select v-model="employee.shift" id="shift" class="form-control" required>
                                                <option value="">-- Choisissez une plage horaire --</option>
                                                <option v-for="shift in shifts" :key="shift.id" :value="shift.symbol"> {{
                                                    shift.label }} </option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="form-line">
                                            <select v-model="employee.status" class="form-control" required>
                                                <option value="">Choisir le type de contrat</option>
                                                <option value="permanent">Permanent</option>
                                                <option value="consultant">Consultant</option>
                                                <option value="temporaire">Temporaire</option>
                                            </select>
                                        </div>
                                    </td>
                                    <td>
                                        <button
                                            v-on:click="updateEmployee(employee.id,employee.matricule, employee.first_name, employee.last_name, employee.email, employee.phone, employee.profile_id, employee.shift, employee.status)"
                                            type="button"
                                            class="btn bg-blue btn-circle waves-effect waves-circle waves-float">
                                            <i class="material-icons">send</i>
                                        </button>
                                        -
                                        <button v-on:click="removeEmployee(employee.id)" type="button"
                                            class="btn bg-red btn-circle waves-effect waves-circle waves-float">
                                            <i class="material-icons">delete</i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; justify-content:center" v-else>
                        <div class="preloader pl-size-xs">
                            <div class="spinner-layer pl-indigo">
                                <div class="circle-clipper left">
                                    <div class="circle"></div>
                                </div>
                                <div class="circle-clipper right">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- RAPPORT GLOBAL -->
    <div class="modal fade" id="globalrapport" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div id="global">
                    <div class="modal-body table-responsive">
                        <div v-if="!rapport_load">
                            <h3>
                                <center><span class="label bg-black">Rapport de présence global</span> </center>
                            </h3>
                            <center style="margin-bottom: 15px; margin-top: 15px;"><span>Période du {{ rapport.debut }} au
                                    {{ rapport.fin }}</span></center>
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>
                                            <center>#</center>
                                        </th>
                                        <th>
                                            <center>Nom<br>Prénoms</center>
                                        </th>
                                        <th>
                                            <center>Nombre<br>Présences</center>
                                        </th>
                                        <th>
                                            <center>Nombre<br>Absences</center>
                                        </th>
                                        <th>
                                            <center>Nombre<br>Retards</center>
                                        </th>
                                        <th>
                                            <center>
                                                Départs anticipé
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                Arrivée moyenne
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                Départ moyen
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                Heures de Travail
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                Heure supplémentaire
                                            </center>
                                        </th>
                                        <th>
                                            <center>
                                                Taux ponctualité
                                            </center>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="user in rapport.users" :key="user.id">
                                        <td>
                                            <center>{{ user.order }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.first_name }} {{ user.last_name }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.presences }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.absences }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.retards }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.departs }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.moyennearrivee }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.moyennedepart }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.ratespresences }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.overtime }}</center>
                                        </td>
                                        <td>
                                            <center>{{ user.ratesponctuality }}%</center>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else style="display: flex; justify-content:center; margin-top: 50px;">
                            <div class="preloader pl-size-xs">
                                <div class="spinner-layer pl-indigo">
                                    <div class="circle-clipper left">
                                        <div class="circle"></div>
                                    </div>
                                    <div class="circle-clipper right">
                                        <div class="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <!-- <button type="button" class="btn btn-success waves-effect" v-on:click="excel('global')">EXCEL</button> -->
                    <button type="button" class="btn btn-primary waves-effect" v-on:click="ExportToExcel('xlsx', null, null,'global')">XLSX</button>
                    <button type="button" class="btn btn-primary waves-effect" v-on:click="pdf('global')">PDF</button>
                    <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">X</button>
                </div>
            </div>
        </div>
    </div>

    <!-- RAPPORT INDIVIDUEL -->
    <div v-if="rapportfilter == 'indiv'">
        <div class="modal fade" id="individualrapport" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div id="indiv">
                        <div class="modal-body table-responsive">
                            <div v-if="!rapport_load">
                                <h3>
                                    <center><span class="label bg-black">Rapport de présence: {{
                                        indivrapport.user.first_name
                                    }} {{ indivrapport.user.last_name }} </span> </center>
                                </h3>
                                <center style="margin-bottom: 15px; margin-top: 15px;"><span>Période du {{
                                    indivrapport.debut }}
                                        au
                                        {{ indivrapport.fin }}</span></center>

                                <!-- RESUME -->
                                <span class="label bg-blue-grey">Resumé</span>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <center>Nombre<br>Présences</center>
                                            </th>
                                            <th>
                                                <center>Nombre<br>Absences</center>
                                            </th>
                                            <th>
                                                <center>Nombre<br>Retards</center>
                                            </th>
                                            <th>
                                                <center>
                                                    Départs<br>anticipé
                                                </center>
                                            </th>
                                            <th>
                                                <center>
                                                    Arrivée<br>moyenne
                                                </center>
                                            </th>
                                            <th>
                                                <center>
                                                    Départ<br>moyen
                                                </center>
                                            </th>
                                            <th>
                                                <center>
                                                    Heures<br>de Travail
                                                </center>
                                            </th>
                                            <th>
                                                <center>
                                                    Heures<br>Supplémentaire
                                                </center>
                                            </th>
                                            <th>
                                                <center>
                                                    Taux<br>ponctualité
                                                </center>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <center>{{ indivrapport.user.presences }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.absences }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.retards }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.departs }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.moyennearrivee }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.moyennedepart }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.ratespresences }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.overtime }}</center>
                                            </td>
                                            <td>
                                                <center>{{ indivrapport.user.ratesponctuality }}%</center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- EXCEPTIONS -->
                                <span class="label bg-blue-grey">Exceptions</span>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <center>Type</center>
                                            </th>
                                            <th>
                                                <center>Début</center>
                                            </th>
                                            <th>
                                                <center>Fin</center>
                                            </th>
                                            <th>
                                                <center>Temps manqué</center>
                                            </th>
                                            <th>
                                                <center>
                                                    Statut
                                                </center>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="exception in indivrapport.exceptions" :key="exception.id">
                                            <td>
                                                <center>{{ exception.type.label }}</center>
                                            </td>
                                            <td>
                                                <center>{{ exception.date_start }}</center>
                                            </td>
                                            <td>
                                                <center>{{ exception.date_end }}</center>
                                            </td>
                                            <td>
                                                <center>{{ exception.hour_issue }}</center>
                                            </td>
                                            <td>
                                                <center>
                                                    <span v-if="exception.state == '2'"
                                                        class="label label-default bg-green">VALIDÉE</span>
                                                    <span v-else-if="exception.state == '1'"
                                                        class="label label-default bg-orange">EN COURS DE VALIDATION</span>
                                                    <span v-else class="label label-default bg-red">NON JUSTIIÉE</span>
                                                </center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <!-- PRESENCES -->
                                <span class="label bg-blue-grey">Présences</span>
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <center>Date</center>
                                            </th>
                                            <th>
                                                <center>Arrivée</center>
                                            </th>
                                            <th>
                                                <center>Départ</center>
                                            </th>
                                            <th>
                                                <center>Zone</center>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="presence in indivrapport.presences" :key="presence.id">
                                            <td>
                                                <center>{{ presence.created_at }}</center>
                                            </td>
                                            <td>
                                                <center>{{ presence.hour_start }}</center>
                                            </td>
                                            <td>
                                                <center>{{ presence.hour_end }}</center>
                                            </td>
                                            <td>
                                                <center>{{ presence.zone.label }}</center>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div v-else style="display: flex; justify-content:center; margin-top: 50px;">
                                <div class="preloader pl-size-xs">
                                    <div class="spinner-layer pl-indigo">
                                        <div class="circle-clipper left">
                                            <div class="circle"></div>
                                        </div>
                                        <div class="circle-clipper right">
                                            <div class="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary waves-effect" v-on:click="ExportToExcel('xlsx', null, null,'indiv')">XLSX</button>
                        <button type="button" class="btn btn-primary waves-effect" v-on:click="pdf('indiv')">PDF</button>
                        <button type="button" class="btn btn-danger waves-effect" data-dismiss="modal">X</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import html2pdf from "html2pdf.js"
import Swal from 'sweetalert2'
import axios from 'axios'
import * as XLSX from 'xlsx/xlsx.mjs'

export default {
    name: 'EmployeeTableComponent',
    components: {

    },
    data() {
        return {
            indivrapport: { user: {}, presences: [], exceptions: [], debut: null, fin: null },
            rapport: { users: [], debut: null, fin: null },
            rapportfilter: 'global',
            selectedemp: "",
            rapport_load: false,
            table_load: false,
            employees: [],
            profiles: [],
            shifts: [],
        }
    },
    computed: {
        comp1() {
            return ""
        }
    },
    async mounted() {
        await this.getProfiles()
        await this.getData()
        await  this.getShifts()
    },
    created() {

    },
    methods: {

        async getData() {
            this.table_load = true
            axios.get(this.$store.state.URL_API + 'getAllEmployees')
                .then((res) => {
                    this.employees = res.data.data
                    this.table_load = false

                })
                .catch((error) => {
                    console.error(error)
                    this.table_load = false
                })
        },

        ExportToExcel(type, fn, dl, table) {
        var elt = document.getElementById(table);
        var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
        return dl ?
            XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
            XLSX.writeFile(wb, fn || ('Sells sheet - '+ new Date() +'.' + (type || 'xlsx')));
        },

        async getProfiles() {
            axios.get(this.$store.state.URL_API + 'getAllProfiles')
                .then((res) => {
                    this.profiles = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async getShifts() {
            await axios.get(this.$store.state.URL_API + 'shifts')
                .then((res) => {
                    this.shifts = res.data.data
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        async updateEmployee(id,matricule, first_name, last_name, email, phone, profile_id, shift,status) {
            Swal.fire({
                title: "Confirmer la modification",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    console.log({
                        first_name: first_name,
                        last_name: last_name,
                        email: email, phone: phone,
                        profile_id: profile_id
                    })
                    await axios.post(this.$store.state.URL_API + 'updateEmployee/' + id,
                        {
                            matricule: matricule,
                            first_name: first_name,
                            last_name: last_name,
                            email: email, phone: phone,
                            profile_id: profile_id,
                            shift: shift,
                            status: status
                        })
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Informations modifiées',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            Swal.fire(
                                'Oops..',
                                error.response.data.message,
                                'error'
                            )
                            console.error(error)
                        })
                }
            })

        },

        async removeEmployee(id) {
            Swal.fire({
                title: "Confirmer la suppression",
                text: "Cette action est ireversible",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.$store.state.URL_API + 'removeEmployee/' + id)
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Employé supprimé',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },

        async getrapportglobal() {
            this.rapportfilter = 'global'
            this.rapport_load = true;
            axios.get(this.$store.state.URL_API + 'employeeRapportGlobal')
                .then((res) => {
                    this.rapport.users = res.data.data
                    this.rapport.debut = res.data.debut
                    this.rapport.fin = res.data.fin
                    this.rapport_load = false
                })
                .catch((error) => {
                    console.error(error)
                    this.rapport_load = false
                })
        },

        async getrapportindiv(id) {
            if (this.rapportfilter == 'indiv') {
                this.rapport_load = true;
                axios.get(this.$store.state.URL_API + 'employeeRapportIndiv/' + id)
                    .then((res) => {
                        this.indivrapport.exceptions = res.data.exceptions
                        this.indivrapport.presences = res.data.presences
                        this.indivrapport.user = res.data.data
                        this.indivrapport.debut = res.data.debut
                        this.indivrapport.fin = res.data.fin
                        this.rapport_load = false
                    })
                    .catch((error) => {
                        console.error(error)
                        this.rapport_load = false
                    })
            }
        },

        async resetmacadress() {
            Swal.fire({
                title: "Confirmer la réinitialisation",
                text: "",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Je confirme !',
                cancelButtonText: "J'annule",
                showCancelButton: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.get(this.$store.state.URL_API + 'resetMacAdress/' + this.selectedemp)
                        .then(async (res) => {
                            if (res.data.status) {
                                await this.getData()
                                Swal.fire(
                                    'Succès !',
                                    'Adresse Mac Réinitialisée',
                                    'success'
                                )
                            } else {
                                Swal.fire(
                                    'Oops..',
                                    res.data.message,
                                    'error'
                                )
                            }
                        })
                        .catch((error) => {
                            console.error(error)
                            this.table_load = false
                        })
                }
            })

        },

        pdf(id) {
            html2pdf(document.getElementById(id));
        },

        excel(id) {
            html2pdf(document.getElementById(id));
        }
    }
}

</script>