<template>
    <tr>
        <td>
            <div class="form-line">
                #{{ demande.id }}
            </div>
        </td>
        <td>
            <div class="form-line">
                {{ demande.document.title }}
            </div>
        </td>
        <td>
            <div class="form-line">
                <input type="file" @change="previewFile" id="file" placeholder="Sélectionner le fichier">
            </div>
        </td>
        <td>
            <div class="form-line">
                {{ demande.validation_rh == 1 ? "Validé" : "En cours" }}
            </div>
        </td>
        <td>
            <button
                v-on:click="updateDocument(demande.id)"
                type="button" class="btn bg-blue btn-circle waves-effect waves-circle waves-float">
                <i class="material-icons">send</i>
            </button>
            -
            <button v-on:click="removeDocument(demande.id)" type="button"
                class="btn bg-red btn-circle waves-effect waves-circle waves-float">
                    <i class="material-icons">delete</i>
                </button>
            </td>
        </tr>
</template>
<script>
import API from '../../service/API';
import toast from '@/components/ui/toast';
export default {
    name: "DemandeDocItem",
    props:["demande"],
    components:{},
    data(){
        return {
            file:null
        }
    },
    methods:{
        updateDocument(id){
            const formData = new FormData();
            formData.append("id",id);
            formData.append("file",this.file);
            formData.append("validation_rh",1);
            API.post("/doc-updated",formData)
            .then((data)=>{
                if(data.data["status"]){
                    toast.success("Succès","Fichier ajouter à la demande",3000);
                    this.getAllDocType();
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
        removeDocument(id){
            API.delete("/user-docs/"+id)
            .then(data=>{
                if(data.data["status"]){
                    toast.success("Succès","Demande de document supprimé",3000);
                    this.getAllDocType()
                }else{
                    toast.error("Oops..",data.data["message"],4000)
                }
            })
            .catch(()=>{
                toast.error("Oops..","Il semble avoir une erreur, merci de réessayé.",4000)
            })
        },
        previewFile(event) {
            if(event.target.files.length == 0) return;
            this.file = event.target.files[0];
        }
    }
}
</script>